export default {
    en: {
        body: "Body",
        booking: "Booking",
        class: "Class",
        close: "Close",
        created: "Created",
        customer_name: "Customer Name",
        last_activity: "Last Activity",
        reference: "Reference",
        requester: "Requester",
        status: "Status",
        status_closed: "Closed",
        status_in_progress: "In Progress",
        status_open: "Open",
        subject: "Subject",
    },
};
