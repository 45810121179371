import Vue from "vue";
import { Ticket, TrainingClass } from "../graphql-types";
import { get } from "lodash";
import { DateTime } from "luxon";

const descriptor: Record<string, any> = {
    describeTicket(item: Ticket): string {
        return `${item.booking?.reference} - ${item.booking?.title}`;
    },

    describeTrainingClass(trainingClass: TrainingClass): string {
        let title = trainingClass.title;
        if (trainingClass.startsAt) {
            const startDate = DateTime.fromISO(trainingClass.startsAt, {
                zone: "UTC",
            });

            title = `${title} (${startDate.toLocaleString({
                weekday: "short",
                day: "numeric",
                month: "short",
                year: "numeric",
            })})`;
        }
        return title;
    },
};

Vue.filter("descriptor", (value: any): string => {
    const typeName = get(value, "__typename", "");
    const action = descriptor[`describe${typeName}`];

    if (typeof action === "function") {
        return action(value);
    }

    return "Descriptor not found.";
});
